* {
    font-family: 'DM Sans';
}

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Covered+By+Your+Grace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500;600;700;800&display=swap');

.email {
    text-decoration: underline;
    cursor: pointer;
    color: #373737;
}

.email:hover {
    text-decoration: none;
}

.inButton:disabled {
    cursor: not-allowed;
}

.view:hover {
    opacity: 100;
}

.bB {
    font-weight: bold;
}

.top {
    margin-top: 5%;
}

.color {
    color: #585858bd;
}

/* Additional styling for specific details */
.detailT:nth-child(odd) {
    text-transform: uppercase;
}

.detailC:nth-child(3n) {
    text-transform: capitalize;
}

.detailC:nth-child(4n) {
    font-style: italic;
}

.detailC:nth-child(5n) {
    color: #775a25;
    /* Change color for Max Per Wallet */
}

.activeNav {
    background: linear-gradient(to bottom, #a07b34, #bb9b51);
    -webkit-background-clip: text;
    color: transparent;
}

#font {
    color: black;
}

a {
    color: black;

}

.chainsT {
    color: white;
    font-family: 'Anton', sans-serif;
}

button {
    cursor: pointer;
}

.dashH {
    font-family: 'Anton', sans-serif;
    cursor: pointer;
}

.formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.726);
}

.spanR {
    color: rgb(185, 185, 185);
}

.subT {
    font-family: 'Anton', sans-serif;
}

.s2:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #ffffff48;
    cursor: pointer;
}

.connectReg:hover {
    color: rgb(182, 182, 182);
}

.allWrap {
    background-color: rgb(243, 243, 243);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
}

.dashMain {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
    background-color: white;
}

.light2 {
    height: 100vh;
    min-height: 100vh;
    height: max-content;
}

@media screen and (max-width: 499px) {
    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 100px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 5px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 8px;
        font-weight: bold;
    }

    .view {
        width: 30px;
        height: 30px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .centered {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .aboutH2 {
        font-weight: bold;
        font-size: 18px;
    }

    li {
        text-align: left;
    }


    .rlT2 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border-radius: 50px;
        text-align: center;
    }

    .MainD img {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 2px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
        font-size: 12px;
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;

    }

    .addmm {
        background-color: #bd9244;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        color: white;
        width: 100px;
        margin-top: 10px;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 30px;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 60px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .aboutT2-2 {
        font-size: 18px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 15px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 5px;
        margin-bottom: 100px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .forPC {
        display: none;
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 30px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 100px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }


    .rlT {
        text-align: center;
        font-size: 12px;
    }

    .rlD {
        font-size: 16px;
    }

    .note {
        color: black;
        margin-top: 5px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 50px;
    }

    .miniBarMain {
        padding-bottom: 10px;
        padding-top: 10px;
        width: 90%;
        padding-left: 10%;
        padding-right: 5%;
        background-color: #000000c2;
        display: flex;
        justify-content: space-between;

        margin-left: auto;
        margin-right: auto;
    }

    .miniBar {
        display: flex;
        justify-content: space-between;
        background-color: #3c79b300;
    }

    .navbarMain {
        padding-right: 30px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000000000000000000;
    }

    .navbar {
        width: 27px;
        justify-content: flex-end;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        z-index: 1000000000000000000;
        margin-top: 10px;
    }

    .littleNav div {
        text-align: center;
        padding: 20px;
        cursor: pointer;
        font-family: 'DM Sans';
        color: white;
        border-bottom: 1px solid rgba(48, 48, 48, 0.493);
    }

    .littleNav {
        background-color: #1a1a1ae8;
        width: 100%;
        flex-flow: column wrap;
        display: flex;
        border-bottom: 5px #ffffff50 solid;
        transition: opacity 0.5s ease-in-out;
        /* Transition for fade effect */
        animation: slideRight 0.5s forwards;
        /* Initial slide-in animation */
        padding-bottom: 50px;
    }

    @keyframes slideRight {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .hidden {
        display: none;
    }

    .navLink {
        color: #ff000091;
    }

    .littleNav img:hover {
        transform: scale(1.1);
    }

    .socialIcon {
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .socialIcon img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-left: 10px;
        transition: transform .6s;
    }

    .socialIcon img:hover {
        transform: scale(1.2);
        cursor: pointer;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #00000000;
        z-index: 1000000;
        display: none;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }


    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 16px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: rgb(255, 255, 255);
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 28px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 20px;
        margin-right: 20px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 13px;
        width: 120px;
        background-color: #1b1b1b;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
        padding-top: 50px;
        padding-bottom: 5%;
        /* background-color: #2d4153f8;*/
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 50%;
        height: 50%;
        margin-top: -2%;
        display: none;
    }

    .coinMob {
        width: 70%;
        height: 70%;
        margin-top: -5%;
        margin-left: auto;
        margin-right: auto;
    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }


    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.135em;
        color: gray;
        text-align: center;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 30px;
        color: black;
        line-height: 42px;
        margin-top: 10px;
        margin-bottom: 18px;
        text-align: center;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
        margin-bottom: 5px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: black;
        padding: 12px;
        border: none;
        width: 150px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 20px;
        font-size: 13px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 160px;
        padding: 8px;
        border-radius: 3px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: white;
        padding: 8px;
        border: none;
        width: 160px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        font-family: 'DM Sans';
        border-radius: 3px;
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
        height: fit-content;
        padding-bottom: 30px;
    }

    .header {
        color: black;
        text-align: center;
        font-size: 23px;
        margin-bottom: 20px;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 23px;
        color: rgb(0, 0, 0);
        text-align: center;
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 50px;
    }

    .icons {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 30%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
    background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;
    }

    .registerMain {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;

    }

    .socialMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 80%;
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 27px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        font-size: 14px;
    }

    .b1 img {
        width: 30px;
        height: 30px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .b1 {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .socialMain2 {
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 80%;
        padding: 12px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 21px;
        box-shadow: 0 0 20px #ffffff48;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 14px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
    }


    .subBox {
        display: flex;
        justify-content: space-between;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        border: 2px solid rgb(134, 134, 134);
        margin-bottom: 55px;
        padding: 30px;
    }

    .subBox img {
        width: 55%;
        height: 55%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 20px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 14px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;

    }

    .deploy {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }


    .deploy2 {
        width: 85%;
        height: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .aboutT2 {
        font-size: 14px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        text-align: center;
    }

    .aboutT2-22 {
        font-size: 14px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        text-align: left;
    }

    .about2 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 12px;
        margin-top: 3px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .iconsF img {
        width: 22px;
        height: 22px;
        margin-left: 7px;
        margin-right: 7px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 14px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .network img {
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: transform 0.5s ease;
        padding: 5px;
    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.1);
        padding: 5px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 1px solid gray;
        background-color: #373737;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-bottom: 30px;
        margin-top: 30px;

    }

    .connectNetworks2 {
        border: 1px solid gray;
        background-color: #373737;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-top: 60px;
    }

    .mainDiv {
        max-width: 80%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 50px;
        height: 50px;
        margin-top: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 10px;
        margin: 8px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
    }

    textarea {
        width: 93%;
        height: 300px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 10px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 80%;
        height: 70vh;
        top: 55%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 30px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 27px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 23px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
        font-size: 14px;
    }


    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 30px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 8px;
        text-align: center;
        color: black;
        font-size: 12px;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }

    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10vh;
        overflow-x: scroll;
        margin-bottom: 70px;

    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 100px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 5px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 8px;
        font-weight: bold;
    }

    .view {
        width: 30px;
        height: 30px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .leftT {
        text-align: left;
    }

    .centered {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .aboutH2 {
        font-weight: bold;
        font-size: 18px;
    }

    li {
        text-align: left;
    }

    .rlT2 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border-radius: 50px;
        text-align: center;
    }

    .MainD img {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 2px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
        font-size: 15px;
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;

    }

    .addmm {
        background-color: #bd9244;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        color: white;
        width: 100px;
        margin-top: 10px;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 30px;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 60px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .aboutT2-2 {
        font-size: 18px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 15px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 5px;
        margin-bottom: 100px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .forPC {
        display: none;
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 25px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 100px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }

    .rlT {
        text-align: center;
        font-size: 12px;
    }

    .rlD {
        font-size: 16px;
    }

    .note {
        color: black;
        margin-top: 5px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 50px;
    }

    .miniBarMain {
        padding-bottom: 10px;
        padding-top: 10px;
        width: 90%;
        padding-left: 10%;
        padding-right: 5%;
        background-color: #000000c2;
        display: flex;
        justify-content: space-between;

        margin-left: auto;
        margin-right: auto;
    }

    .miniBar {
        display: flex;
        justify-content: space-between;
        background-color: #3c79b300;
    }

    .navbarMain {
        padding-right: 30px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000000000000000000;
    }

    .navbar {
        width: 30px;
        justify-content: flex-end;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        z-index: 1000000000000000000;
        margin-top: 10px;
        margin-right: 25px;
    }

    .littleNav div {
        text-align: center;
        padding: 20px;
        cursor: pointer;
        font-family: 'DM Sans';
        color: white;
        border-bottom: 1px solid rgba(48, 48, 48, 0.493);
    }

    .littleNav {
        background-color: #1a1a1ae8;
        width: 100%;
        flex-flow: column wrap;
        display: flex;
        border-bottom: 5px #ffffff50 solid;
        transition: opacity 0.5s ease-in-out;
        /* Transition for fade effect */
        animation: slideRight 0.5s forwards;
        /* Initial slide-in animation */
        padding-bottom: 50px;
    }

    @keyframes slideRight {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .hidden {
        display: none;
    }

    .navLink {
        color: #ff000091;
    }

    .littleNav img:hover {
        transform: scale(1.1);
    }

    .socialIcon {
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .socialIcon img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-left: 10px;
        transition: transform .6s;
    }

    .socialIcon img:hover {
        transform: scale(1.2);
        cursor: pointer;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #00000000;
        z-index: 1000000;
        display: none;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }


    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 16px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: rgb(255, 255, 255);
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #fdeaaf, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 28px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 20px;
        margin-right: 20px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 13px;
        width: 120px;
        background-color: #1b1b1b;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
        padding-top: 50px;
        padding-bottom: 5%;
        /* background-color: #2d4153f8;*/
    }

    .introduction {
        margin-top: -15px;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 50%;
        height: 50%;
        margin-top: -2%;
        display: none;
    }

    .coinMob {
        width: 60%;
        height: 60%;
        margin-top: -5%;
        margin-left: auto;
        margin-right: auto;
    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }


    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.135em;
        color: gray;
        text-align: center;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 40px;
        color: black;
        line-height: 58px;
        margin-top: 10px;
        margin-bottom: 18px;
        text-align: center;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
        margin-bottom: 5px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: rgb(0, 0, 0);
        padding: 12px;
        border: none;
        width: 150px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 20px;
        font-size: 15px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 160px;
        padding: 8px;
        border-radius: 3px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: white;
        padding: 8px;
        border: none;
        width: 160px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        font-family: 'DM Sans';
        border-radius: 3px;
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
        height: fit-content;
        padding-bottom: 30px;
    }

    .header {
        color: black;
        text-align: center;
        font-size: 25px;
        margin-bottom: 20px;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 25px;
        color: rgb(0, 0, 0);
        text-align: center;
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 50px;
    }

    .icons {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 35%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding: 30px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
    background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;

    }

    .registerMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;

    }

    .socialMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 80%;
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .b1 img {
        width: 35px;
        height: 35px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .b1 {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .socialMain2 {
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 80%;
        padding: 15px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 22px;
        box-shadow: 0 0 20px #ffffff48;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 16px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
    }

    .subBox {
        display: flex;
        justify-content: space-between;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        border: 2px solid rgb(134, 134, 134);
        margin-bottom: 55px;
        padding: 30px;
    }

    .subBox img {
        width: 55%;
        height: 55%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 28px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 14px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;

    }

    .deploy {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }



    .deploy2 {
        width: 45%;
        height: 45%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .aboutT2 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        text-align: center;
    }

    .aboutT2-22 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        text-align: left;
    }

    .about2 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 12px;
        margin-top: 5px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    .iconsF img {
        width: 25px;
        height: 25px;
        margin-left: 12px;
        margin-right: 12px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 16px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .network img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        transition: transform 0.5s ease;
        padding: 5px;
    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.1);
        padding: 5px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 1px solid gray;
        background-color: #373737;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-bottom: 30px;
        margin-top: 30px;

    }

    .connectNetworks2 {
        border: 1px solid gray;
        background-color: #373737;
        width: 92%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-top: 60px;
    }

    .mainDiv {
        max-width: 70%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 50px;
        height: 50px;
        margin-top: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 10px;
        margin: 8px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
    }

    textarea {
        width: 93%;
        height: 300px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 10px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 80%;
        height: 70vh;
        top: 55%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 30px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 27px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 23px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
        font-size: 14px;
    }


    table {
        border-collapse: collapse;
        margin-top: 30px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 8px;
        text-align: center;
        color: black;
        font-size: 12px;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }

    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10vh;
        overflow-x: scroll;
        margin-bottom: 70px;

    }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 100px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 5px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 8px;
        font-weight: bold;
    }

    .view {
        width: 30px;
        height: 30px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .centered {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .aboutH2 {
        font-weight: bold;
        font-size: 18px;
    }

    li {
        text-align: left;
    }

    .rlT2 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border-radius: 50px;
        text-align: center;
    }

    .MainD img {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 2px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
        font-size: 12px;
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
        font-size: 12px;

    }

    .addmm {
        background-color: #bd9244;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        color: white;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 30px;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 60px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 25px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 100px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }

    .rlT {
        text-align: center;
        font-size: 12px;
    }

    .rlD {
        font-size: 16px;
    }

    .note {
        color: black;
        margin-top: 5px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 50px;
    }

    .miniBarMain {
        padding-bottom: 10px;
        padding-top: 10px;
        width: 90%;
        padding-left: 10%;
        padding-right: 5%;
        background-color: #000000c2;
        display: flex;
        justify-content: space-between;

        margin-left: auto;
        margin-right: auto;
    }

    .miniBar {
        display: flex;
        justify-content: space-between;
        background-color: #3c79b300;
    }

    .navbarMain {
        padding-right: 30px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000000000000000000;
    }

    .navbar {
        width: 30px;
        justify-content: flex-end;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        z-index: 1000000000000000000;
        margin-top: 20px;
        margin-right: 55px;
    }

    .littleNav div {
        text-align: center;
        padding: 20px;
        cursor: pointer;
        font-family: 'DM Sans';
        color: white;
        border-bottom: 1px solid rgba(48, 48, 48, 0.493);
    }

    .littleNav {
        background-color: #1a1a1ae8;
        width: 100%;
        flex-flow: column wrap;
        display: flex;
        border-bottom: 5px #ffffff50 solid;
        transition: opacity 0.5s ease-in-out;
        /* Transition for fade effect */
        animation: slideRight 0.5s forwards;
        /* Initial slide-in animation */
        padding-bottom: 50px;
    }

    @keyframes slideRight {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .hidden {
        display: none;
    }

    .navLink {
        color: #ff000091;
    }

    .littleNav img:hover {
        transform: scale(1.1);
    }

    .socialIcon {
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .socialIcon img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-left: 10px;
        transition: transform .6s;
    }

    .socialIcon img:hover {
        transform: scale(1.2);
        cursor: pointer;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #00000000;
        z-index: 1000000;
        display: none;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }


    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 16px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: rgb(255, 255, 255);
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 28px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 20px;
        margin-right: 20px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 13px;
        width: 120px;
        background-color: #1b1b1b;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
        padding-top: 3%;
        padding-bottom: 5%;
        /* background-color: #2d4153f8;*/
    }

    .introduction {
        margin-top: -15px;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 50%;
        height: 50%;
        margin-top: -2%;
        display: none;
    }

    .coinMob {
        width: 60%;
        height: 60%;
        margin-top: -5%;
        margin-left: auto;
        margin-right: auto;
    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }


    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.135em;
        color: gray;
        text-align: center;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 50px;
        color: black;
        line-height: 68px;
        margin-top: 10px;
        margin-bottom: 18px;
        text-align: center;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
        margin-bottom: 5px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: rgb(0, 0, 0);
        padding: 15px;
        border: none;
        width: 160px;
        margin-top: 30px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }


    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 20px;
        font-size: 15px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 160px;
        padding: 10px;
        border-radius: 3px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: white;
        padding: 10px;
        border: none;
        width: 160px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        font-family: 'DM Sans';
        border-radius: 3px;
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
        height: fit-content
    }

    .header {
        color: black;
        text-align: center;
        font-size: 27px;
        margin-bottom: 20px;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 27px;
        color: rgb(0, 0, 0);
        text-align: center;
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 23px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 50px;
    }

    .icons {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 25%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding: 50px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
    background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;

    }

    .registerMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;

    }

    .socialMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 60%;
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 30px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .b1 img {
        width: 35px;
        height: 35px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .b1 {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        flex-flow: column nowrap;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 55px;
        height: 55px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .socialMain2 {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 40%;
        padding: 15px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: 25px;
        box-shadow: 0 0 20px #ffffff48;

    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 16px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
    }

    .subBox {
        display: flex;
        justify-content: space-evenly;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        border: 2px solid rgb(134, 134, 134);
        margin-bottom: 55px;
        padding: 20px;
    }

    .subBox img {
        width: 45%;
        height: 45%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 37px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 16px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;

    }

    .deploy {
        width: 30%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .deploy2 {
        width: 45%;
        height: 45%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .aboutT2 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        text-align: center;
    }

    .aboutT2-22 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        text-align: left;
    }

    .aboutT2-2 {
        font-size: 18px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 15px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 5px;
        margin-bottom: 100px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .forPC {
        display: none;
    }

    .about2 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 12px;
        margin-top: 5px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }

    .iconsF img {
        width: 25px;
        height: 25px;
        margin-left: 12px;
        margin-right: 12px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 16px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .network img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        transition: transform 0.5s ease;
        padding: 10px;
    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.1);
        padding: 10px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 1px solid gray;
        background-color: #373737;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-bottom: 30px;
        margin-top: 30px;

    }

    .connectNetworks2 {
        border: 1px solid gray;
        background-color: #373737;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-top: 60px;
    }

    .mainDiv {
        max-width: 70%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 50px;
        height: 50px;
        margin-top: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 10px;
        margin: 8px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
    }

    textarea {
        width: 96%;
        height: 300px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 10px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 80%;
        height: 70vh;
        top: 55%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 30px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 32px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 27px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
    }


    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 30px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 8px;
        text-align: center;
        color: black;
        font-size: 14px;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }

    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10vh;
        margin-bottom: 70px;

    }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 100px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 5px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 8px;
        font-weight: bold;
    }

    .view {
        width: 30px;
        height: 30px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .aboutH2 {
        font-weight: bold;
        font-size: 18px;
    }

    .rlT2 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border-radius: 50px;
        text-align: center;
    }

    .MainD img {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 2px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
    }

    .addmm {
        background-color: #bd9244;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        color: white;
    }

    .ForMob {
        display: none;
    }

    .forMob {
        display: none;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 2%;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 60px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 25px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 100px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }

    .rlT {
        text-align: center;
    }

    .rlD {
        font-size: 18px;
    }

    .note {
        color: black;
        margin-top: 5px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 5%;
    }

    .miniBarMain {
        display: none;
    }

    .coinMob {
        display: none;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }


    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 14px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: black;
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 28px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 20px;
        margin-right: 20px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 13px;
        width: 120px;
        background-color: #1b1b1b;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4%;
        padding-bottom: 5%;
        padding-left: 5%;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 50%;
        height: 50%;
        margin-top: -2%;

    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }

    .introduction {
        margin-top: auto;
        margin-bottom: auto;
    }

    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.135em;
        color: gray;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 50px;
        color: black;
        line-height: 68px;
        margin-top: 10px;
        margin-bottom: 18px;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
        margin-bottom: 5px;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: black;
        padding: 15px;
        border: none;
        width: 160px;
        margin-top: 3%;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        margin-top: 20px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 20px;
        font-size: 15px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 160px;
        padding: 10px;
        border-radius: 3px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: white;
        padding: 10px;
        border: none;
        width: 160px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        font-family: 'DM Sans';
        border-radius: 3px;
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
        height: max-content;
    }

    .header {
        color: black;
        text-align: center;
        font-size: 27px;
        margin-bottom: 20px;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 27px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 23px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 50px;
    }

    .icons {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 20%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: rgb(27, 27, 27);
        padding: 50px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
    background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;

    }

    .registerMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 50px;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;

    }

    .socialMain {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 40%;
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 30px;

    }

    .b1 img {
        width: 35px;
        height: 35px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .b1 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 55px;
        height: 55px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .socialMain2 {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 40%;
        padding: 15px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: 25px;
        box-shadow: 0 0 20px #ffffff48;

    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 16px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
    }

    .subBox {
        display: flex;
        justify-content: space-evenly;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        border: 2px solid rgb(134, 134, 134);
        margin-bottom: 55px;
        padding: 20px;
    }

    .subBox img {
        width: 45%;
        height: 45%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 45px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 18px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: space-evenly;
    }

    .deploy {
        width: 20%;
        height: 20%;

    }

    .deploy2 {
        width: 30%;
        height: 30%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .aboutT2 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-22 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-2 {
        font-size: 18px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 15px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 5px;
    }


    .about2 {
        width: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 14px;
        margin-top: 5px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 1%;
    }

    .iconsF img {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 16px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .network img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        transition: transform 0.5s ease;
        padding: 10px;

    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.1);
        padding: 10px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 1px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-bottom: 30px;
        margin-top: 30px;

    }

    .connectNetworks2 {
        border: 1px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-top: 6%;
    }

    .mainDiv {
        max-width: 50%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 50px;
        height: 50px;
        margin-top: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 10px;
        margin: 8px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
    }

    textarea {
        width: 96%;
        height: 300px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 10px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 80%;
        height: 85vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 30px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 35px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 27px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
    }


    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 30px;
        font-size: 14px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 8px;
        text-align: center;
        color: black;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }

    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10vh;
        margin-bottom: 70px;

    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 35px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 100px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 5px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 8px;
        font-weight: bold;
    }

    .view {
        width: 30px;
        height: 30px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }


    .aboutH2 {
        font-weight: bold;
        font-size: 20px;
    }

    .rlT2 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border-radius: 50px;
        text-align: center;
    }

    .MainD img {
        width: 15px;
        height: 15px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 2px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
    }

    .addmm {
        background-color: #bd9244;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        color: white;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 2%;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 60px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .rlT {
        text-align: center;
    }

    .rlD {
        font-size: 16px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 5%;
    }

    .note {
        color: black;
        margin-top: 5px;
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 30px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 100px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }

    .miniBarMain {
        display: none;
    }

    .coinMob {
        display: none;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 16px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: black;
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 30px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 25px;
        margin-right: 25px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #1b1b1b;
        padding: 15px;
        font-size: 18px;
        width: 160px;
        border: none;
        color: white;
        letter-spacing: 2px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 14.81px;
        margin-left: 20px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4%;
        padding-bottom: 5%;
        padding-left: 5%;
        margin-top: -25px;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 50%;
        height: 50%;
        margin-top: -2%;

    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }

    .introduction {
        margin-top: auto;
        margin-bottom: auto;
    }

    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.135em;
        color: gray;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 60px;
        color: black;
        line-height: 82.33px;
        margin-top: 1%;
        margin-bottom: 2.5%;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: rgb(0, 0, 0);
        padding: 15px;
        border: none;
        width: 160px;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 3px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 2%;
        font-size: 16px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 160px;
        padding: 10px;
        border-radius: 3px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: white;
        padding: 10px;
        border: none;
        width: 160px;
        margin-top: 1%;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        font-family: 'DM Sans';
        border-radius: 3px;
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
    }

    .header {
        color: black;
        text-align: center;
        font-size: 30px;
        margin-bottom: 2%;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 40px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 30px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 5%;
    }

    .icons {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 20%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding: 50px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
    background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;
    }

    .registerMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;
    }

    .socialMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 30px;

    }

    .b1 img {
        width: 35px;
        height: 35px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .b1 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 55px;
        height: 55px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .socialMain2 {
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 15px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: 25px;
        box-shadow: 0 0 20px #ffffff48;
    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 16px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
    }

    .subBox {
        display: flex;
        justify-content: space-evenly;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        border: 2px solid rgb(134, 134, 134);
        margin-bottom: 5%;
        padding: 10px;
    }

    .subBox img {
        width: 40%;
        height: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 50px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 18px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: space-evenly;
    }

    .deploy {
        width: 20%;
        height: 20%;
    }

    .deploy2 {
        width: 30%;
        height: 30%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .aboutT2 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-22 {
        font-size: 16px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-2 {
        font-size: 18px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 15px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 5px;
    }

    .about2 {
        width: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 14px;
        margin-top: 5px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 1%;
    }

    .iconsF img {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 16px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .network img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        transition: transform 0.5s ease;
        padding: 10px;
    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.2);
        padding: 10px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 1px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 5px;
        margin-bottom: 3%;
        margin-top: 3%;
    }

    .connectNetworks2 {
        border: 1px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 5px;
        margin-top: 6%;
    }

    .mainDiv {
        max-width: 50%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 50px;
        height: 50px;
        margin-top: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 10px;
        margin: 8px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
    }

    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
    }

    textarea {
        width: 97%;
        height: 300px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 10px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 70%;
        height: 85vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 30px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 35px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 27px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
    }


    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 3%;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 10px;
        text-align: center;
        color: black;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }

    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 5%;
    }

    .forMob {
        display: none;
    }
}

@media screen and (min-width: 1920px) {

    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 30px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 20px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 40px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 150px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 10px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 22px;
    }

    .view {
        width: 40px;
        height: 40px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .aboutH2 {
        font-weight: bold;
        font-size: 28px;
    }

    .forMob {
        display: none;
    }

    .rlT2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        border-radius: 50px;
        text-align: center;

    }

    .MainD img {
        width: 25px;
        height: 25px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        border-bottom: 4px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
        font-size: 24px;
    }

    .addmm {
        background-color: #bd9244;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        color: white;
        font-size: 24px;
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
        font-size: 24px;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 2%;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 90px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 45px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 100px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }


    .rlT {
        text-align: center;
        font-size: 20px;
    }

    .rlD {
        font-size: 23px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 5%;
        font-size: 23px;
    }

    .note {
        color: black;
        margin-top: 10px;
        font-size: 23px;
    }

    .miniBarMain {
        display: none;
    }

    .coinMob {
        display: none;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 27px;
        padding-bottom: 27px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 27px;
        padding-bottom: 27px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 23px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: black;
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 45px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 35px;
        margin-right: 35px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        color: #bb9b51;
        background-color: #1b1b1b;

        padding: 23px;
        width: 230px;
        border: none;
        color: white;
        letter-spacing: 3px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        margin-left: 30px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4%;
        padding-bottom: 5%;
        padding-left: 5%;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 100%;
        height: 100%;
        margin-top: -2%;

    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }

    .introduction {
        margin-top: auto;
        margin-bottom: auto;
    }

    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        letter-spacing: 0.135em;
        color: gray;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 82px;
        color: black;
        line-height: 115px;
        margin-top: 1%;
        margin-bottom: 2.5%;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        color: rgb(0, 0, 0);
        padding: 23px;
        border: none;
        width: 230px;
        margin-top: 3%;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        margin-top: 30px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 2%;
        font-size: 22px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 230px;
        padding: 15px;
        border-radius: 5px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        color: white;
        padding: 15px;
        border: none;
        width: 230px;
        margin-top: 1%;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        font-family: 'DM Sans';
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
    }

    .header {
        color: black;
        text-align: center;
        font-size: 45px;
        margin-bottom: 2%;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 45px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 30px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 5%;
    }

    .icons {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 20%;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 40px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding: 60px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
        background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;
    }

    .registerMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;
    }

    .socialMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 20px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 25px;
        margin-bottom: 20px;
        margin-top: 20px;
        line-height: 45px;
        font-size: 23px;
    }

    .b1 img {
        width: 55px;
        height: 55px;
        margin-bottom: -10px;
        margin-right: 10px;
    }

    .b1 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 75px;
        height: 75px;
        margin-right: 10px;
    }

    .socialMain2 {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 20px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 20px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: 37px;
        box-shadow: 0 0 20px #ffffff48;

    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 23px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
    }

    .subBox {
        display: flex;
        justify-content: space-evenly;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 14px;
        border: 3px solid rgb(134, 134, 134);
        margin-bottom: 5%;
        padding: 10px;
    }

    .subBox img {
        width: 40%;
        height: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 60px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 24px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: space-evenly;
    }

    .deploy {
        width: 20%;
        height: 20%;

    }

    .deploy2 {
        width: 30%;
        height: 30%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .aboutT2 {
        font-size: 23px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-22 {
        font-size: 23px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-2 {
        font-size: 25px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 20px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 8px;
    }

    .about2 {
        width: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 20px;
        margin-top: 10px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 1%;
    }

    .iconsF img {
        width: 43px;
        height: 43px;
        margin-left: 15px;
        margin-right: 15px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 23px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .network img {
        cursor: pointer;
        width: 45px;
        height: 45px;
        transition: transform 0.5s ease;
        padding: 15px;
    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.2);
        padding: 15px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 1px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 10px;
        margin-bottom: 3%;
        margin-top: 3%;

    }

    .connectNetworks2 {
        border: 1px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 10px;
        margin-top: 6%;
    }

    .mainDiv {
        max-width: 50%;
        padding: 40px;
        border: 1px solid #ccc;
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 70px;
        height: 70px;
        margin-top: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 15px 20px;
        font-size: 23px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        margin-top: 20px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 15px;
        margin: 12px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
        font-size: 20px;
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
        font-size: 23px;
    }

    textarea {
        width: 97%;
        height: 300px;
        resize: vertical;
        font-family: 'DM Sans';
        font-size: 20px;
        padding: 15px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
        font-size: 20px;

    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 70%;
        height: 85vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 50px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 45px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 35px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
        font-size: 23px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 3%;
        font-size: 23px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 8px;
        text-align: center;
        color: black;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }


    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 5%;

    }

}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 50px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 30px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 60px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 200px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 10px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 18px;
        font-weight: bold;
        font-size: 28px;
    }

    .view {
        width: 50px;
        height: 50px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }


    .aboutH2 {
        font-weight: bold;
        font-size: 36px;
    }

    .forMob {
        display: none;
    }

    .rlT2 {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
        border-radius: 50px;
        text-align: center;

    }

    .MainD img {
        width: 30px;
        height: 30px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        margin-top: 40px;
        border-bottom: 4px solid #775a25;
        /* Gold border */
        padding-bottom: 15px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
        font-size: 32px;
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
        font-size: 32px;
    }

    .addmm {
        background-color: #bd9244;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        color: white;
        font-size: 32px;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 2%;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 140px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }


    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 65px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 300px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }

    .rlT {
        text-align: center;
        font-size: 30px;
    }

    .rlD {
        font-size: 33px;
    }

    .note {
        color: black;
        margin-top: 20px;
        font-size: 33px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 5%;
        font-size: 33px;
    }

    .miniBarMain {
        display: none;
    }

    .coinMob {
        display: none;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #fontSize {
        font-size: 31.5px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: black;
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 60px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 45px;
        margin-right: 45px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 29px;
        width: 300px;
        background-color: #1b1b1b;
        border: none;
        color: white;
        letter-spacing: 3px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 29px;
        margin-left: 40px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 7px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4%;
        padding-bottom: 5%;
        padding-left: 5%;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 100%;
        height: 100%;
        margin-top: -2%;

    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }

    .introduction {
        margin-top: auto;
        margin-bottom: auto;
    }

    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 29.5px;
        letter-spacing: 0.135em;
        color: gray;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 110px;
        color: black;
        line-height: 155px;
        margin-top: 1%;
        margin-bottom: 2.5%;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 31.5px;
        color: black;
        padding: 29px;
        border: none;
        width: 300px;
        margin-top: 45px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 7px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 2%;
        font-size: 29px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 300px;
        padding: 20px;
        border-radius: 5px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectReg:hover {
        color: rgb(182, 182, 182);
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 2%;
        font-size: 29px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1px solid white;
        width: 300px;
        padding: 20px;
        border-radius: 5px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 29px;
        color: white;
        padding: 20px;
        border: none;
        width: 300px;
        margin-top: 1%;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        font-family: 'DM Sans';
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
    }

    .header {
        color: black;
        text-align: center;
        font-size: 62px;
        margin-bottom: 2%;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 75px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 65px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 5%;
    }

    .icons {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 20%;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 30px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding: 90px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
        background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;
    }

    .registerMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background: rgb(0, 0, 0);
        background-color: #d0d0d0;

    }

    .socialMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 28px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1.5px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 30px;
        margin-bottom: 40px;
        margin-top: 40px;
        line-height: 63px;
        font-size: 31.5px;
    }

    .b1 img {
        width: 72px;
        height: 72px;
        margin-bottom: -20px;
        margin-right: 20px;
    }

    .b1 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 110px;
        height: 110px;
        margin-right: 10px;
    }

    .socialMain2 {
        margin-bottom: 57px;
        margin-top: 57px;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 35px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 35px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: 45px;
        box-shadow: 0 0 20px #ffffff48;
    }

    .s2:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px #ffffff48;
    }

    .subS {
        font-family: 'DM Sans';
        font-size: 31.5px;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
    }

    .subBox {
        display: flex;
        justify-content: space-evenly;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 18px;
        border: 4px solid rgb(134, 134, 134);
        margin-bottom: 5%;
        padding: 10px;
    }

    .subBox img {
        width: 40%;
        height: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-size: 95px;
        font-family: 'Anton', sans-serif;
    }

    .mainT2 {
        font-size: 33px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: space-evenly;
    }

    .deploy {
        width: 20%;
        height: 20%;

    }

    .deploy2 {
        width: 30%;
        height: 30%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .aboutT2-22 {
        font-size: 31.5px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2 {
        font-size: 31.5px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-2 {
        font-size: 33px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 30px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 10px;
    }

    .about2 {
        width: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 27px;
        margin-top: 10px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 1%;
    }

    .iconsF img {
        width: 58px;
        height: 58px;
        margin-left: 20px;
        margin-right: 20px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 31.5px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .network img {
        cursor: pointer;
        width: 58px;
        height: 58px;
        transition: transform 0.5s ease;
        padding: 22px;
    }

    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.2);
        padding: 22px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 3px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 12px;
        margin-bottom: 3%;
        margin-top: 3%;
    }

    .connectNetworks2 {
        border: 3px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 12px;
        margin-top: 6%;
    }


    .mainDiv {
        max-width: 50%;
        padding: 50px;
        border: 1px solid #ccc;
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 90px;
        height: 90px;
        margin-top: 2%;
        margin-bottom: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 20px 30px;
        font-size: 31.5px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 30px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 20px;
        margin: 16px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
        font-size: 27px;
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 10px 18px;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        color: white;
        font-size: 31.5px;
    }

    textarea {
        width: 97%;
        height: 400px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 20px;
        font-size: 27px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
        font-size: 27px;

    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 70%;
        height: 85vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 50px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 67px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 50px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
        font-size: 31.5px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 3%;
        font-size: 31.5px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 15px;
        text-align: center;
        color: black;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }


    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 5%;

    }


}

@media screen and (min-width: 3840px) {
    .modal-container {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 10% auto;
        padding: 80px;
        border: 1px solid #888;
        width: 100%;
        max-width: 90%;
        border-radius: 40px;
        overflow-y: scroll;
    }

    .close {
        color: #ffffff;
        float: right;
        font-size: 100px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: rgb(255, 227, 167);
        text-decoration: none;
        cursor: pointer;
    }

    #imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    #imageContainer img {
        width: 300px;
        /* Adjust the width as per your requirement */
        height: auto;
        margin: 10px;
    }

    .identifier {
        display: block;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 25px;
        font-weight: bold;
        font-size: 40px;
    }

    .view {
        width: 70px;
        height: 70px;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .aboutH2 {
        font-weight: bold;
        font-size: 55px;
    }

    .forMob {
        display: none;
    }

    .rlT2 {
        font-size: 45px;
        font-weight: bold;
        margin-bottom: 2%;
        background-color: #775a254d;
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 30px;
        border-radius: 100px;
        text-align: center;

    }

    .MainD img {
        width: 45px;
        height: 45px;
        opacity: 0.5;
        transition: 0.2 ease-in-out;
        cursor: pointer;
    }

    .MainD img:hover {
        opacity: 1;
    }

    .MainD {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        margin-top: 50px;
        border-bottom: 6px solid #775a25;
        /* Gold border */
        padding-bottom: 25px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .detailT {
        font-weight: bold;
        flex: 1;
        margin-right: 10px;
        color: #775a25;
        /* Gold text color */
        font-size: 45px;
    }

    .detailC {
        flex: 2;
        color: #adadad;
        /* Black text color */
        font-size: 45px;
    }

    .addmm {
        background-color: #bd9244;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        border: none;
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        color: white;
        font-size: 45px;
    }

    .stickyBtn {
        position: fixed;
        bottom: 10%;
        /* Adjust as needed */
        right: 0;
        /* This will stick the button to the right side */
        z-index: 999;
        /* Ensure button is above other content */
        margin-right: 2%;
        cursor: pointer;
    }

    .stickyBtn img.stickyBtn2 {
        /* Additional styling for the image inside the button */
        width: 200px;
        /* Adjust image size as needed */
        height: auto;
        /* Ensures aspect ratio is maintained */
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 85px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        width: 400px;
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .closeNote:hover {
        color: rgb(218, 218, 218);
        background-color: #2c993000;
    }

    .rlT {
        text-align: center;
        font-size: 40px;
    }

    .rlD {
        font-size: 50px;
    }

    .note {
        color: black;
        margin-top: 25px;
        font-size: 50px;
    }

    .dashP2 {
        text-align: center;
        color: rgba(133, 133, 133, 0.616);
        margin-top: 5%;
        font-size: 50px;
    }

    .miniBarMain {
        display: none;
    }

    .coinMob {
        display: none;
    }

    .headers {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h1 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .headers2 {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #00000000;
        z-index: 1000000;
    }

    .h2 {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }


    #fontSize {
        font-size: 46.5px;
        cursor: pointer;
        z-index: 10000;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 500;
        color: black;
        font-family: 'DM Sans';
    }

    #fontSize:hover {
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
    }

    .logoDiv {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        font-size: 90px;
        display: flex;
        background: linear-gradient(to bottom, #775a25, #fdeaaf);
        -webkit-background-clip: text;
        color: transparent;
        font-family: 'Anton', sans-serif;
    }

    .connect {
        display: flex;
        flex-flow: row nowrap;
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
    }

    .connect div {
        font-family: 'Archivo Black', sans-serif;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        display: flex;
        cursor: pointer;
        transition: 0.5s ease;
        color: white;
        margin-left: 65px;
        margin-right: 65px;
        text-transform: uppercase;
        font-family: 400;
    }

    .connectBtn {
        background-color: #0084ff00;
        color: rgb(0, 0, 0);
        padding: 39px;
        width: 440px;
        background-color: #1b1b1b;
        border: none;
        color: white;
        letter-spacing: 3px;
        position: relative;
        overflow: hidden;
        transition: background-color 0.5s ease-in-out;
        transition: 0.2s ease-in-out;
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        margin-left: 40px;
        transition: 0.3s ease-in-out;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        font-family: 'DM Sans';
    }

    .connectBtn:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn:hover::before {
        left: 0;
    }

    .connect2 {
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        margin-top: auto;
        margin-bottom: auto;
    }

    .introductionMain {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4%;
        padding-bottom: 5%;
        padding-left: 5%;
    }

    .introductionPic {
        width: 55%;
        height: 100%;
        margin-top: auto;
        margin-left: 40%;
        margin-top: -5%;
    }

    .coin {
        width: 100%;
        height: 100%;
        margin-top: -2%;

    }

    @keyframes floating {

        0%,
        100% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);
        }
    }

    .introduction {
        margin-top: auto;
        margin-bottom: auto;
    }

    .in1 {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        letter-spacing: 0.135em;
        color: gray;
    }

    .hl {
        color: #05a0df;
    }

    .in2 {
        font-size: 170px;
        color: black;
        line-height: 240px;
        margin-top: 1%;
        margin-bottom: 2.5%;
        font-family: 'Anton', sans-serif;
    }

    .in3 {
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        color: gray;
        font-family: 'DM Sans';
        width: 90%;
    }

    .connectBtn2 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 46.5px;
        color: black;
        border: none;
        padding: 39px;
        width: 440px;
        margin-top: 60px;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        font-family: 'Anton', sans-serif;
    }

    .connectBtn2:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn2::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn2:hover::before {
        left: 0;
    }

    .connectReg {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        margin-top: 2%;
        font-size: 45px;
        background-color: #1d1d1d;
        font-family: 'Anton', sans-serif;
        border: 1.5px solid white;
        width: 440px;
        padding: 35px;
        border-radius: 10px;
        color: rgb(153, 150, 150);
        transition: 0.2s ease-in-out;
    }

    .connectBtn3 {
        box-shadow: 0px 24px 55px #ffdc8957;
        background-color: #bb9b51;
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        color: white;
        border: none;
        padding: 35px;
        width: 440px;
        margin-top: 1%;
        transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        border-radius: 10px;
        font-family: 'DM Sans';
    }

    .connectBtn3:hover {
        /* Specify the final background color */
        background-color: #dabe67;
        color: rgb(0, 0, 0);
    }

    .connectBtn3::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #bb9b51);
        transition: left 0.3s ease-in-out;
        /* Adjusted transition property */
    }

    .connectBtn3:hover::before {
        left: 0;
    }

    .cont {
        background: rgb(185, 185, 185);
        background: linear-gradient(40deg, rgba(185, 185, 185, 1) 0%, rgba(241, 241, 241, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(241, 241, 241, 1) 80%, rgba(185, 185, 185, 1) 100%);
    }

    .header {
        color: black;
        text-align: center;
        font-size: 90px;
        margin-bottom: 2%;
        font-family: 'Anton', sans-serif;
    }

    .aboutT {
        font-size: 110px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
    }

    .aboutT-2 {
        font-size: 100px;
        color: rgb(0, 0, 0);
        font-family: 'Anton', sans-serif;
        text-align: center;
        margin-bottom: 5%;
    }

    .icons {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        display: flex;
        flex-flow: row wrap;
    }

    .icons img {
        width: 20%;
        margin-left: 60px;
        margin-right: 60px;
        margin-top: 60px;
        opacity: 0.5;
        transition: 0.5s ease-in-out;
    }

    .icons img:hover {
        opacity: 1;
    }

    .chainsMain {
        background-color: #606060;
        padding: 150px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 25px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .benefitsMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        /*background: rgb(19, 0, 28);
        background: linear-gradient(86deg, rgba(19, 0, 28, 1) 0%, rgb(41, 41, 41) 49%, rgba(19, 0, 28, 1) 100%);*/
        background-color: #f6f6f6;
    }

    .registerMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        background: url('../images/cyber.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
    }

    .footerMain {
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-left: auto;
        margin-right: auto;
        background-color: rgb(22, 22, 22);
        background: url('../images/footer.png') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: scroll;
        background-color: black;
    }

    .aboutMain {
        width: 100%;
        padding-bottom: 5%;
        padding-top: 5%;
        margin-left: auto;
        margin-right: auto;
        background-color: #d0d0d0;

    }

    .socialMain {
        width: 100%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
    }

    .b1 div {
        background: rgba(43, 43, 43, 0.76);
        border-radius: 40px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1.5px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 50px;
        margin-bottom: 60px;
        margin-top: 60px;
        line-height: 85px;
        font-size: 46.5px;
        ;
    }

    .b1 img {
        width: 110px;
        height: 110px;
        margin-bottom: -25px;
        margin-right: 30px;
    }

    .b1 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .socialMain2 img {
        width: 155px;
        height: 155px;
        margin-right: 30px;
    }

    .socialMain2 {
        margin-bottom: 90px;
        margin-top: 90px;
    }

    .s2 {
        display: flex;
        justify-content: space-evenly;
        border-radius: 45px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-family: 'DM Sans';
        color: white;
        width: 30%;
        padding: 50px;
        background: rgb(25, 25, 25);
        background: linear-gradient(263deg, rgba(25, 25, 25, 1) 0%, rgba(48, 48, 48, 1) 49%, rgba(76, 76, 76, 1) 100%);
        transition: transform 0.5s ease;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: 72px;
        box-shadow: 0 0 20px #ffffff48;

    }

    .subS {
        font-family: 'DM Sans';
        font-size: 46.5px;
        ;
    }

    .socialMain2 {
        display: flex;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
    }

    .benefitsMain2 {
        margin-left: auto;
        margin-right: auto;
    }

    .subBox {
        display: flex;
        justify-content: space-evenly;
        background: rgb(41, 41, 41);
        background: radial-gradient(circle, rgba(41, 41, 41, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(60, 60, 60, 1) 100%);
        color: white;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 28px;
        border: 6px solid rgb(134, 134, 134);
        margin-bottom: 5%;
        padding: 10px;
    }

    .subBox img {
        width: 40%;
        height: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .subBox2 {
        margin-top: auto;
        margin-bottom: auto;
    }

    .mainT {
        font-family: 'Anton', sans-serif;
        font-size: 155px;
    }

    .mainT2 {
        font-size: 50px;
        font-family: 'DM Sans';
    }

    @keyframes zoomInOut {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }

    .aboutMain2 {
        display: flex;
        justify-content: space-evenly;
    }

    .deploy {
        width: 20%;
        height: 20%;

    }

    .deploy2 {
        width: 30%;
        height: 30%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .aboutT2 {
        font-size: 46.5px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-22 {
        font-size: 46.5px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
    }

    .aboutT2-2 {
        font-size: 50px;
        font-family: 'DM Sans';
        color: black;
        font-weight: 500;
        margin-top: 2%;
        background-color: black;
        color: white;
        padding: 45px;
        color: rgba(255, 255, 255, 0.692);
        border-radius: 15px;
    }

    .about2 {
        width: 40%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .footer1 {
        color: rgb(88, 88, 88);
        text-align: center;
        font-family: 'DM Sans';
        font-size: 38px;
        margin-top: 15px;
    }

    .iconsF {
        display: flex;
        justify-content: center;
        margin-bottom: 1%;
    }

    .iconsF img {
        width: 87px;
        height: 87px;
        margin-left: 30px;
        margin-right: 30px;
        transition: transform 0.5s ease;
        cursor: pointer;
    }

    .iconsF img:hover {
        transform: scale(1.2);
    }

    .registerT {
        font-size: 46.5px;
        text-align: center;
        font-family: 'DM Sans';
        color: grey;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .network {
        display: flex;
        justify-content: space-evenly;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .network img {
        cursor: pointer;
        width: 85px;
        height: 85px;
        transition: transform 0.5s ease;
        padding: 30px;
    }

    .network img:hover {
        transform: scale(1.2);
    }


    .network img:hover {
        transform: scale(1.2);
    }

    .network img.selected {
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        background-color: rgb(29, 29, 29);
        transform: scale(1.2);
        padding: 30px;
        border: 1px solid goldenrod;
    }

    .connectNetworks {
        border: 3px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 15px;
        margin-bottom: 3%;
        margin-top: 3%;

    }

    .connectNetworks2 {
        border: 3px solid gray;
        background-color: #373737;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3%;
        padding-bottom: 3%;
        border-radius: 15px;
        margin-top: 6%;
    }

    .mainDiv {
        max-width: 50%;
        padding: 60px;
        border: 1px solid #ccc;
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .closeI {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        width: 150px;
        height: 150px;
        margin-top: 2%;
        margin-bottom: 2%;
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .close:hover {
        transform: scale(1.1);
    }

    .inButton {
        background-color: #4d4d4d;
        color: white;
        padding: 30px 40px;
        font-size: 46.5px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 40px;
        transition: 0.2s ease-in-out;
        font-family: 'DM Sans';
    }

    .inButton:hover {
        background-color: #575757;
    }

    /* Style for the input fields */
    input {
        width: 100%;
        padding: 30px;
        margin: 22px 0;
        box-sizing: border-box;
        font-family: 'DM Sans';
        font-size: 38px;
    }


    .dash {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: 0.2s ease-in-out;
    }

    .dash:hover {
        background-color: #858383;
    }

    .verify {
        background-color: #a07a34;
        padding: 18px 26px;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        color: white;
        font-size: 46.5px;
    }

    textarea {
        width: 97%;
        height: 600px;
        resize: vertical;
        font-family: 'DM Sans';
        padding: 30px;
        font-size: 38px;
    }

    .mintingAmount2 {
        color: black;
        font-family: 'DM Sans';
        font-size: 38px;

    }

    .popUpDiv {
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.856);
        position: fixed;
        width: 70%;
        height: 85vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: scroll;
        overflow-x: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: rgb(0, 0, 0);
        padding: 80px;
        border: rgb(255, 255, 255) 2px dashed;
        animation: popupAnimation 0.5s ease forwards;
        color: white;
        flex-flow: column nowrap;
    }

    .dashT {
        color: rgb(110, 110, 110);
        font-size: 100px;
        font-family: 'Anton', sans-serif;
    }

    .dashT2 {
        color: rgb(153, 153, 153);
        font-size: 80px;
    }

    .dashP {
        font-family: 'DM Sans';
        color: rgb(177, 177, 177);
        font-size: 46.5px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 3%;
        font-size: 46.5px;
        background-color: gainsboro;
    }

    th,
    td {
        border-bottom: 1px solid #858383;
        padding: 25px;
        text-align: center;
        color: black;
    }

    th {
        color: black;
        background-color: black;
        color: white;
    }


    .mainContent {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4%;
        margin-bottom: 5%;

    }


}